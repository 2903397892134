/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-28 10:35:22
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-13 17:41:47
 */
export const columns = [
{
    title: '品牌logo',
    dataIndex: 'brandName',
    key: 'brandName',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '商品名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '商品型号',
    dataIndex: 'specStr',
    key: 'specStr',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '商品价格',
    dataIndex: 'price',
    key: 'price',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'price'}
},
{
    title: '商品图片',
    dataIndex: 'image',
    key: 'image',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'image'}
},
{
    title: '是否上架',
    dataIndex: 'flagUp',
    key: 'flagUp',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'flagUp'}
},
]
